export const Divider: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <div
      className={`w-full border-b border-neutral20_to_dark30 ${
        className ? className : ""
      }`}
    />
  );
};
