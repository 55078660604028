import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import { pushDataLayer } from "lib/gtag";
import { usePopupStoreFormSales } from "modules/Home/popupStore";

export const DataInfo = {
  industry: "200+",
  courses: "1.000+",
  learners: "700K+",
  partners: "50+",
  trainers: '50+',
};

const DataInformation = [
  {
    title: DataInfo.courses,
    description: `kursus-kursus soft skill & tren industri terkini dari ${DataInfo.partners} perusahaan dan universitas top dunia`
  },
  {
    title: DataInfo.learners,
    description: `jumlah pelajar yang menggunakan goKampus sebagai tempat pembelajaran`
  },
  {
    title: DataInfo.industry,
    description: `perusahaan dari berbagai industri yang telah menggunakan goKampus Business`
  },
]

export const HomeInformation: React.FC = () => {
  const { showPopupFormSales } = usePopupStoreFormSales((state) => ({
    showPopupFormSales: state.showPopupFormSales,
  }));

  const onClickImages = () => {
    pushDataLayer({
      event: "cta-partner",
    });
    showPopupFormSales("");
  };

  return (
    <section id="home-information">
      <ContainerDesktop className="pt-20 pb-52 sm-max:py-36" useAuto>
        <div className="grid grid-cols-3 md-max:grid-cols-1 gap-16 ">
          {DataInformation.map((value) => (
            <div className="shadow-chip_total p-24 rounded-16 bg-chip-pattern">
              <p className="text-h1 text-purple-50 font-bold">
                {value.title}
              </p>
              <p className="font-light">
                {value.description}
              </p>
            </div>
          ))}
        </div>
      </ContainerDesktop>
    </section>
  );
};
