import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import ButtonContact from "components/Button/ButtonContact";
import ImageAutoplay from "components/Autoplay/ImageAutoplay";
import Link from "next/link";
import { usePopupStoreFormSales } from "modules/Home/popupStore";
import { DataInfo } from "./HomeInformation";
import { pushDataLayer } from "lib/gtag";
import Images from "next/image";
import { useEffect, useRef, useState } from "react";

export const HomeDescription: React.FC = () => {
  const headingSectionDescription = TranslationID["HomeDescription"];
  const headingTranslationButton = TranslationID["Button"];
  const { showPopupYoutube } = usePopupStoreFormSales((state) => ({
    showPopupYoutube: state.showPopupYoutube,
  }));

  const onClickVideo = () => {
    pushDataLayer({ event: "cta-video" });
    showPopupYoutube();
  };

  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // first prevent the default behavior
    e.preventDefault();
    // get the href and remove everything before the hash (#)
    const href = e.currentTarget.href;
    const targetId = href.replace(/.*\#/, "");
    // get the element by id and use scrollIntoView
    const elem = document.getElementById(targetId);
    elem?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  const renderBusinessChip = (className, title, description) => {
    return (
      <div
        className={`bg-total-pattern rounded-12 shadow-chip_total border border-white p-8 flex flex-col items-center justify-center h-fit ${className}`}
      >
        <div className="font-black text-h4 text-purple-50">{title}</div>
        <div className="font-light text-b1 2xl-max:text-b2">{description}</div>
      </div>
    );
  };

  const videoRef = useRef(null);
  const progressBarRef = useRef(null);
  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    {
      id: 1,
      start: 0,
      end: 70,
      no: "1",
      title: "Feedback Cerdas dari AI",
      description:
        "Dapatkan umpan balik khusus dari AI yang dirancang untuk membantu Anda mengasah kompetensi dan meningkatkan performa kerja.",
    },
    {
      id: 2,
      start: 70,
      end: 140,
      no: "2",
      title: "Diskusi Profesional yang Terarah",
      description:
        "AI menjaga percakapan tetap fokus pada topik profesional. Jika ada pertanyaan di luar konteks pekerjaan, AI akan mengarahkan kembali ke topik yang relevan.",
    },
    {
      id: 3,
      start: 140,
      end: 210,
      no: "3",
      title: "Tingkatkan Kinerja dengan AI",
      description:
        "Optimalkan performa Anda dengan wawasan AI untuk mendorong produktivitas dan hasil kerja yang maksimal.",
    },
  ];

  useEffect(() => {
    const video = videoRef.current;
    const progressBar = progressBarRef.current;

    const updateProgressBar = () => {
      if (video && progressBar) {
        const percentage = (video.currentTime / video.duration) * 100;
        progressBar.style.width = `${percentage}%`;
      }
    };

    const handleTimeUpdate = () => {
      const currentTime = video.currentTime;
      const currentSection = sections.find(
        (section) => currentTime >= section.start && currentTime < section.end
      );
      if (currentSection) {
        setActiveSection(currentSection.id);
      }
    };

    const handleVideoEnd = () => {
      setActiveSection(null);
    };

    video.addEventListener("timeupdate", handleTimeUpdate);
    video.addEventListener("timeupdate", updateProgressBar);
    video.addEventListener("ended", handleVideoEnd);

    return () => {
      video.removeEventListener("timeupdate", handleTimeUpdate);
      video.removeEventListener("timeupdate", updateProgressBar);
      video.removeEventListener("ended", handleVideoEnd);
    };
  }, [sections]);

  const handleSectionClick = (section) => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = section.start;
      setActiveSection(section.id);
    }
  };

  useEffect(() => {
    const handlePlay = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };

    const handleUnmute = () => {
      setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.muted = false;
          if (videoRef.current.paused) {
            videoRef.current.play();
          }
        }
      }, 2000);
    };

    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.addEventListener("canplay", handlePlay);
      handleUnmute();
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("canplay", handlePlay);
      }
    };
  }, []);

  return (
    <section id="home-description">
      <ContainerDesktop className="pt-60" useAuto>
        <div className="grid grid-cols-1 gap-x-[42px] xl-max:grid-cols-1">
          <div className="flex flex-col gap-20 text-left items-center mb-28 md:px-200">
            <h1
              dangerouslySetInnerHTML={{
                __html: headingSectionDescription.title,
              }}
              className="font-black text-center text-h2 lg-max:text-h3"
            ></h1>
            <Link
              //@ts-ignore
              href={"#home-packets"}
              onClick={handleScroll}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: headingSectionDescription.description,
                }}
                className="font-light text-h4 lg-max:text-b1 text-center"
              ></div>
            </Link>

            <div className="flex justify-start items-center md:gap-8 sm-max:flex-col sm-max:items-start">
              <ButtonContact
                className=" !h-48 sm:w-full"
                id="cta-top"
                inputStep={1}
              />
            </div>

            <div className="flex gap-50 justify-center items-center mt-12 mb-12">
              <div className="flex gap-8 justify-center items-center">
                <img
                  src="/images/star-landing.svg"
                  alt="star"
                  width="20"
                  height="20"
                />
                <p className="text-purple-50 text-b1 font-light">
                  Integrasi Mudah di Berbagai Platform
                </p>
              </div>
              <div className="flex gap-8 justify-center items-center">
                <img
                  src="/images/star-landing.svg"
                  alt="star"
                  width="20"
                  height="20"
                />
                <p className="text-purple-50 text-b1 font-light">
                  Satu Harga untuk Jumlah Pembelajar Tanpa Batas
                </p>
              </div>
            </div>
          </div>

          <div
            className="bg-white rounded-xl border-4 border-white p-2"
            style={{ boxShadow: "-3px 11px 41px 0px #9F9F9F26" }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                display: "inline-block",
              }}
            >
              <video
                webkit-playsinline="true"
                playsInline
                ref={videoRef}
                className="rounded-xl"
                autoPlay
                loop
                src="https://s.gokampus.com/vid/goKampus+AI+Advisor+-+Demo.mp4"
                data-src="https://s.gokampus.com/vid/goKampus+AI+Advisor+-+Demo.mp4"
                style={{ width: "100%", display: "block" }}
              ></video>

              <div
                className="absolute bottom-30 w-full"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "20px",
                }}
              >
                {sections.map((section) => (
                  <div
                    key={section.id}
                    onClick={() => handleSectionClick(section)}
                    style={{
                      padding: "10px",
                      borderRadius: "8px",
                      color: "white",
                      width: "30%",
                      textAlign: "center",
                      opacity: activeSection === section.id ? 1 : 0.6,
                      transition: "opacity 0.5s",
                      background:
                        activeSection !== section.id &&
                        "linear-gradient(111.61deg, rgba(255, 255, 255, 0.76) 15.17%, rgba(255, 255, 255, 0.64) 95.84%)",
                      backdropFilter:
                        activeSection !== section.id && "blur(13px)",
                      boxShadow:
                        activeSection !== section.id &&
                        "-3px 11px 41px 0px #9F9F9F26",
                    }}
                    className={`${
                      activeSection === section.id && "bg-purple-50"
                    } rounded-xl cursor-pointer`}
                  >
                    <div className="flex justify-center items-center gap-8 mb-4">
                      <h4
                        className={`text-h4 font-bold ${
                          activeSection === section.id
                            ? "text-white"
                            : "text-neutral-80"
                        }`}
                      >
                        {section.title}
                      </h4>
                      <p
                        className={`text-b2 font-medium text-black rounded-full bg-white ${
                          activeSection === section.id
                            ? "w-20 h-20"
                            : "w-22 h-22 border border-black"
                        }`}
                      >
                        {section.no}
                      </p>
                    </div>
                    <p
                      className={`text-b1 font-light ${
                        activeSection === section.id
                          ? "text-white"
                          : "text-neutral-80"
                      }`}
                    >
                      {section.description}
                    </p>
                  </div>
                ))}
              </div>

              <div
                className="bg-purple-50 rounded-tr-xl rounded-br-xl rounded-bl-xl"
                ref={progressBarRef}
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  height: "10px",
                  width: "0%",
                  transition: "width 0.1s linear",
                }}
              ></div>
            </div>
          </div>
        </div>
      </ContainerDesktop>
    </section>
  );
};
