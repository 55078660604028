import { useApi } from "lib/apiFetcher";

export const useCourseDetailApi = (additionalKey: string) =>
  useApi("get", "/courses/{slug}", additionalKey);

export const useCourseLessonDetailApi = (additionalKey: string) =>
  useApi("get", "/lessons/{slug}", additionalKey);

export const useCourseEnrollApi = (param?: string) =>
  // @ts-ignore
  useApi("post", `/courses/{slug}/enroll${param ? `?${param}` : ""}`);

export const useCourseGetQuiz = (additionalKey: string) =>
  useApi("get", "/lessons/{slug}/quiz", additionalKey);

export const useCourseAnswerQuiz = () => useApi("post", "/lessons/{slug}/quiz");
export const useCourseGetAnswerQuiz = (k: string) =>
  // @ts-ignore
  useApi("get", "/lessons/{slug}/quiz/user-answers", k);

export const useCourseGetStatus = (additionalKey: string) =>
  useApi("get", "/courses/{slug}/status", additionalKey);

export const useCourseSetLessonDone = () =>
  useApi("get", "/lessons/{slug}/done");

export const useCourseGetOfflineVideo = () =>
  // @ts-ignore
  useApi("get", "/lessons/{slug}/offline/video");

export const useCourseGenerateCertificate = () =>
  useApi("post", "/courses/{id}/certificate");

export const useCourseGetCertificate = () =>
  useApi("get", "/courses/{uuid}/download-certificate");

export const useCourseReview = () => useApi("post", "/courses/{slug}/review");

export const useCourseWebinar = () =>
  useApi("get", "/webinar/{course_slug}/{lesson_slug}");

export const useCourseGetReview = () => useApi("get", "/courses/{slug}/review");

export const useCourseGetAssignments = () =>
  useApi("get", "/lessons/{slug}/assignment");

export const useCourseSendAssignments = () =>
  useApi("post", "/lessons/{slug}/assignment");

export const useCourseGetAssignmentUserAnswers = () =>
  // @ts-ignore
  useApi("get", "/lessons/{slug}/assignment/user-answers");

export const useCourseGetArticle = (additionalKey: string) =>
  // @ts-ignore
  useApi("get", "/lesson/{slug}/article", additionalKey);

export const useCourseGetCategories = () => useApi("get", "/course-categories");
