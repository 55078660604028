import { ContainerDesktop } from "components/Container";
import HomeTable from "modules/Business/HomeTable";
import React from "react";

const PageHeaderPricing: React.FC<{
  isShow?: boolean;
  useSearch?: boolean;
}> = () => {
  return (
    <div className={"z-50 h-108 sticky top-72 lg-max:hidden"}>
      <ContainerDesktop useAuto>{/* <HomeTable isBodyHidden /> */}</ContainerDesktop>
    </div>
  );
};

export default PageHeaderPricing;
