import TranslationID from "data/locales/id/business.json";
import React, { useState } from "react";
import { usePopupStoreFormSales } from "../Home/popupStore";
import FormFieldText from "components/Form/FieldText";
import { usePostContactBusiness } from "modules/Business/businessApi";
import { useToastStore } from "components/Toast/toastStore";
import Button from "components/Button";
import { pushDataLayer } from "lib/gtag";
import { VideoJS } from "modules/Course/CourseVideoJs";

export const VideoPopUp: React.FC<{ eventId?: string }> = ({
  eventId = "popup-video",
}) => {
  const textDocs = TranslationID["PopupFormSales"];

  const { closePopupFormSales } = usePopupStoreFormSales((state) => ({
    closePopupFormSales: state.closePopupFormSales,
    currentCtaEvent: state.currentCtaEvent,
  }));
  const [contact, setcontact] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const showToast = useToastStore((state) => state.showToast);
  const usePostApi = usePostContactBusiness();

  const submit = () => {
    setIsLoading(true);

    if (!contact) {
      showToast({
        type: "danger",
        message: "Pastikan kontak anda sudah diisi",
      });
      setIsLoading(false);
      return;
    }
    const urlOrigin = window.location.href + "?event=" + eventId;
    usePostApi.doFetch(
      {
        contact,
        url_origin: urlOrigin,
      },
      {
        onSuccess(res) {
          pushDataLayer({ event: "success-leads" });

          closePopupFormSales();

          // reset form field
          setcontact("");

          //@ts-ignore
          if (res.message === "success") {
            showToast({
              type: "success",
              message: "Email anda berhasil terkirim ke tim goKampus",
            });
            setIsLoading(false);
          }
        },
        onError(err) {
          showToast({
            type: "danger",
            message: err.data?.message ?? err.message ?? "Error",
          });
          setIsLoading(false);
        },
      }
    );
  };
  return (
    <div className="flex flex-col gap-16">
      {/* title text */}
      <div
        dangerouslySetInnerHTML={{
          __html: textDocs.title,
        }}
        className="font-black text-h2 lg-max:text-h3"
      ></div>
      <div className="items-center grid grid-cols-2 gap-x-[42px] lg-max:grid-cols-1 2xl-max:gap-y-[42px] h-full  justify-center items-center">
        {/* yt */}
        {/* <iframe
          className="w-full min-h-[50vh] rounded-16"
          src="https://www.youtube.com/embed/zBow1FiMcxk?autoplay=1&mute=1&cc_load_policy=1"
        ></iframe> */}
        <div className="w-full h-[50vh]">
          <VideoJS
            url={"https://s.gokampus.com/business/intro-gokampus-nov-2023.mp4"}
          />
        </div>

        {/* form */}
        <div className="relative justify-center items-center">
          <div className="!bg-white flex flex-col gap-8 shadow-chip_total rounded-16 bg-chip-pattern p-32">
            <div className="font-black text-h3 lg-max:text-h5">
              {textDocs.contactSales}
            </div>
            <div className="font-light text-b1 sm-max:text-b1 py-4">
              {textDocs.contactSalesDesc}
            </div>
            <div className="font-medium text-b2 text-neutral-60">
              {textDocs.fieldContactInput}
            </div>
            <FormFieldText
              name="contact"
              islast={true}
              classBase="h-full sm-max:w-full !mb-16"
              inputStyle="!rounded-8 h-40 sm-max:!w-full"
              placeholder={textDocs.fieldContactPlaceholder}
              value={contact}
              onChange={(e) => setcontact(e.target.value)}
            />
            <Button
              className={`flex justify-center items-center gap-8 !rounded-8 !text-b1`}
              onClick={() => {
                pushDataLayer({ event: eventId });
                submit();
              }}
              isFull
              state={isLoading ? "loading" : "active"}
            >
              {textDocs.fieldSubmit}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
