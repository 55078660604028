type Props = {
  size?: number;
  color?: string;
};

export const IconElectric: React.FC<Props> = ({
  size = 32,
  color = "#FFFFFF",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.937 1.51643L17.9718 1.52711C18.0294 1.55111 18.0746 1.58771 18.1053 1.62838C18.1412 1.67592 18.1569 1.72784 18.1569 1.77652V1.77662V10.6655V12.1655H19.6569H26.9707H26.9713C27.0398 12.1655 27.1044 12.1839 27.1577 12.2153C27.2107 12.2465 27.2481 12.2878 27.2709 12.3303C27.2935 12.3724 27.3023 12.4164 27.2994 12.4585C27.2966 12.5005 27.282 12.5446 27.2526 12.5857C27.2524 12.5859 27.2523 12.5861 27.2522 12.5862L14.4535 30.3633L14.4523 30.365C14.4175 30.4135 14.3622 30.457 14.2894 30.4811C14.2162 30.5054 14.1356 30.5063 14.0615 30.4836C13.9877 30.4611 13.9309 30.4185 13.8946 30.3703C13.8587 30.3227 13.843 30.2708 13.843 30.2221V30.2211V21.3322V19.8322H12.343H5.02922H5.02857C4.96009 19.8322 4.89549 19.8138 4.84222 19.7824C4.78926 19.7512 4.75179 19.7099 4.72901 19.6674C4.70644 19.6253 4.69765 19.5813 4.70046 19.5392C4.70328 19.497 4.71804 19.4527 4.74771 19.4115L17.5462 1.63466C17.5811 1.58618 17.6364 1.54277 17.7092 1.51875L17.2393 0.0942602L17.7092 1.51875C17.7824 1.49461 17.863 1.49372 17.937 1.51643Z"
        stroke={color}
        strokeWidth="3"
      />
    </svg>
  );
};
