import React from "react";

type Props = {
  size?: number;
  color?: string;
  className?: string;
};

export const IconCheckWithOuter: React.FC<Props> = ({
  size = 14,
  color = "#F37A41",
  className = "",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Heroicons/Solid/badge-check">
        <path
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.3873 2.41837C4.83745 2.38245 5.26479 2.20544 5.60849 1.91254C6.41056 1.22902 7.59022 1.22902 8.39229 1.91254C8.73599 2.20544 9.16333 2.38245 9.61348 2.41837C10.6639 2.5022 11.4981 3.33635 11.5819 4.38681C11.6178 4.83696 11.7949 5.2643 12.0878 5.60801C12.7713 6.41007 12.7713 7.58974 12.0878 8.3918C11.7949 8.7355 11.6178 9.16285 11.5819 9.61299C11.4981 10.6635 10.6639 11.4976 9.61348 11.5814C9.16333 11.6174 8.73599 11.7944 8.39229 12.0873C7.59022 12.7708 6.41056 12.7708 5.60849 12.0873C5.26479 11.7944 4.83745 11.6174 4.3873 11.5814C3.33684 11.4976 2.50269 10.6635 2.41886 9.61299C2.38294 9.16285 2.20593 8.7355 1.91303 8.3918C1.22951 7.58974 1.22951 6.41007 1.91303 5.60801C2.20593 5.2643 2.38294 4.83696 2.41886 4.38681C2.50269 3.33635 3.33684 2.5022 4.3873 2.41837ZM9.59537 6.09488C9.86873 5.82151 9.86873 5.37829 9.59537 5.10493C9.322 4.83156 8.87878 4.83156 8.60542 5.10493L6.30039 7.40995L5.39537 6.50493C5.122 6.23156 4.67878 6.23156 4.40542 6.50493C4.13205 6.77829 4.13205 7.22151 4.40542 7.49488L5.80542 8.89488C6.07878 9.16824 6.522 9.16824 6.79537 8.89488L9.59537 6.09488Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
