import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export const IconWarning: React.FC<Props> = ({
  size = 20,
  color = "#318A4E",
}) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className="fill-neutral-60"
    >
      <path
        d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
        // fill={color}
      />
    </svg>
  );
};
