type Props = {
  size?: number;
  color?: string;
};

export const IconBooks: React.FC<Props> = ({
  size = 32,
  color = "#FFFFFF",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.96 25.0933L28.6267 19.9467L24.4267 4.49333C24.3359 4.15245 24.1138 3.86143 23.8089 3.684C23.504 3.50657 23.1412 3.45718 22.8 3.54667L17.64 4.88C17.5169 4.74353 17.3668 4.63399 17.1993 4.55827C17.0318 4.48255 16.8505 4.44228 16.6667 4.44H3.33333C2.97971 4.44 2.64057 4.58048 2.39052 4.83052C2.14048 5.08057 2 5.41971 2 5.77333V27.1067C2 27.4603 2.14048 27.7994 2.39052 28.0495C2.64057 28.2995 2.97971 28.44 3.33333 28.44H16.6667C17.0203 28.44 17.3594 28.2995 17.6095 28.0495C17.8595 27.7994 18 27.4603 18 27.1067V16.44L20.9333 27.4C21.0115 27.6909 21.1858 27.9467 21.4279 28.1259C21.67 28.3051 21.9657 28.397 22.2667 28.3867C22.3819 28.3998 22.4981 28.3998 22.6133 28.3867L29.0533 26.6667C29.2236 26.6211 29.3831 26.5423 29.5227 26.4348C29.6623 26.3272 29.7792 26.193 29.8667 26.04C30.0073 25.745 30.0403 25.4101 29.96 25.0933ZM8.62667 25.8267H4.62667V23.16H8.62667V25.8267ZM8.62667 20.4933H4.62667V12.4933H8.62667V20.4933ZM8.62667 9.82667H4.62667V7.16H8.62667V9.82667ZM15.2933 25.8267H11.2933V23.16H15.2933V25.8267ZM15.2933 20.4933H11.2933V12.4933H15.2933V20.4933ZM15.2933 9.82667H11.2933V7.16H15.2933V9.82667ZM18.2933 7.50667L22.16 6.46667L22.8533 9.04L18.9867 10.08L18.2933 7.50667ZM21.7467 20.3867L19.68 12.6533L23.5467 11.6133L25.6133 19.3467L21.7467 20.3867ZM23.08 25.5333L22.3867 22.96L26.2533 21.92L26.9467 24.4933L23.08 25.5333Z"
        fill={color}
      />
    </svg>
  );
};
