import createStore from "zustand";

export type PacketStore = {
  // state
  selectedType: string;
  isIncludeCertificate: boolean;
  isOpen: boolean;

  // actions
  setSelectedType: (item: string) => void;
  setIncludeCertificate: (item: boolean) => void;
  setIsOpen: (item: boolean) =>void;
};

export const usePacketStore = createStore<PacketStore>((set) => ({
  // state
  selectedType: "Tahun",
  isIncludeCertificate: false,
  isOpen: false,

  // actions
  setSelectedType(item) {
    set({selectedType: item});
  },
  setIncludeCertificate(item) {
    set({isIncludeCertificate: item});
  },
  setIsOpen(item) {
    set({isOpen: item});
  },
}));
